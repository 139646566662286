<template>
  <div class="tablePrint">
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :md="12" :lg="12" :xl="12"
          >{{ $t("message.procedural_list") }}
        </el-col>

        <el-col :xs="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                size="small"
                class="mr-1"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save") }}
              </el-button>

              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="row">
      <div class="col-12">
        <el-card class="box-card mt-2">
          <el-collapse class="open_cliet_hstori mb-4" v-model="activeCollapse">
            <el-collapse-item :title="$t('message.patient_info')" name="1">
              <el-row>
                <el-col :span="6">
                  {{ $t("message.fio") }}:
                  <b>{{
                    order_model.patient
                      ? order_model.patient.surname +
                        " " +
                        order_model.patient.first_name +
                        " " +
                        (order_model.patient.patronymic
                          ? order_model.patient.patronymic
                          : "")
                      : ""
                  }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.phone") }}:
                  <b>{{
                    order_model.patient ? order_model.patient.phone : "-"
                  }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.dob") }}:
                  <b>{{
                    order_model.patient ? order_model.patient.born_date : "-"
                  }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.gender") }}:
                  <b>{{
                    order_model.patient ? order_model.patient.gender : "-"
                  }}</b>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>

          <el-collapse
            class="open_cliet_hstori pt-0 mb-4"
            v-model="activeCollapse2"
          >
            <el-collapse-item :title="$t('message.order_service')" name="1">
              <ul class="ul_usluga">
                <li v-for="(item, i) in order_model.services" :key="i">
                  <div>-{{ item.name }}</div>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </div>
    </div>

    <div class="p-3">
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label"
            ><i class="el-icon-takeaway-box"></i>
            {{ $t("message.products") }}</span
          >
          <div class="center__block_form m-0 p-0">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              class="aticler_m m-0 p-0"
              size="small"
            >
              <el-row :gutter="25">
                <el-col :span="24">
                  <el-form-item
                    :label="$t('message.main_warehouses')"
                    prop="warehouse_id"
                  >
                    <warehouse
                      v-on:clear-x="afterClosed()"
                      v-model="form.warehouse_id"
                      :warehouse_id="form.warehouse_id"
                      size="mini"
                    ></warehouse>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <!-- end col -->
              </el-row>
              <!-- end el-row -->

              <div class="mb-4">
                <div>
                  <el-table
                    border
                    :data="items"
                    :row-class-name="tableRowClassName"
                  >
                    <el-table-column label="#" width="30">
                      <template slot-scope="scope">{{
                        scope.$index + 1
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.name')">
                      <template slot-scope="scope">{{
                        scope.row.product.name
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.available')">
                      <template slot-scope="scope">{{
                        scope.row.available
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.quantity')">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.quantity"
                          type="number"
                          :min="0"
                          :max="scope.row.available"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.actions')">
                      <template slot-scope="scope">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="removeWriteOffItem(scope.row)"
                        ></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <el-row :gutter="25">
                  <el-col :span="11" class="mt-3">
                    <el-form-item
                      v-if="form.warehouse_id"
                      :label="$t('message.products')"
                    >
                      <products
                        @append="append"
                        v-model="form.product_id"
                        :product_id="form.product_id"
                      ></products>
                    </el-form-item>
                    <el-form-item v-else :label="$t('message.products')">
                      <el-select
                        v-model="value"
                        disabled
                        :placeholder="$t('message.products')"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="брак ">
          <span slot="label"
            ><i class="el-icon-document-delete"></i>
            {{ $t("message.invalid") }}</span
          >
          <div class="center__block_form m-0 p-0">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              class="aticler_m m-0 p-0"
              size="small"
            >
              <el-row :gutter="25">
                <el-col :span="24">
                  <el-form-item
                    :label="$t('message.main_warehouses')"
                    prop="warehouse_id"
                  >
                    <warehouse
                      v-on:clear-x="afterClosed()"
                      v-model="form.invalid_warehouse_id"
                      :warehouse_id="form.invalid_warehouse_id"
                      size="mini"
                    ></warehouse>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <!-- end col -->
              </el-row>
              <!-- end el-row -->

              <div class="mb-4">
                <div>
                  <el-table
                    border
                    :data="invalid_items"
                    :row-class-name="tableRowClassName"
                  >
                    <el-table-column label="#" width="30">
                      <template slot-scope="scope">{{
                        scope.$index + 1
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.name')">
                      <template slot-scope="scope">{{
                        scope.row.product.name
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.available')">
                      <template slot-scope="scope">{{
                        scope.row.available
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.quantity')">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.quantity"
                          type="number"
                          :min="0"
                          :max="scope.row.available"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.comment')">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.comment"
                          type="text"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.actions')">
                      <template slot-scope="scope">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="removeWriteOffInvalidItem(scope.row)"
                        ></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <el-row :gutter="25">
                  <el-col :span="11" class="mt-3">
                    <el-form-item
                      v-if="form.invalid_warehouse_id"
                      :label="$t('message.products')"
                    >
                      <products
                        @append="invalid_append"
                        v-model="form.invalid_product_id"
                        :product_id="form.invalid_product_id"
                      ></products>
                    </el-form-item>
                    <el-form-item v-else :label="$t('message.products')">
                      <el-select
                        v-model="value"
                        disabled
                        :placeholder="$t('message.products')"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import warehouse from "@/components/selects/warehouse";
import drawerChild from "@/utils/mixins/drawer-child";
import products from "@/components/selects/products";

export default {
  // mixins: [drawerChild],
  props: {
    serviceId: { default: null },
  },
  components: {
    warehouse,
    products,
  },
  data() {
    return {
      fileList: [],
      favorites: [],
      options: [],
      activeCollapse: "",
      activeCollapse2: "",
      loadingButton: false,
      active: 0,
      value: "",
      activeName: "first",
      template: "",
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      templates: [],
      loadingTemplates: false,
      nextButton: true,
      changeTemplateButton: false,
      selectedTemplate: {},
      old_form: "",
      items: [],
      invalid_items: [],
      form: {
        name: "",
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    form: {
      handler: function () {},
    },
    serviceId: {
      handler: function () {
        if (this.serviceId) {
          this.form.service_id = this.serviceId;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      tempList: "formTemplate/all",
      rules: "conclusions/rules",
      model: "conclusions/model",
      order_model: "orders/relation_model",
      columns: "conclusions/columns",
      user_id: "auth/id",
    }),
  },
  async mounted() {
    if (this.serviceId) {
      this.form.service_id = this.serviceId;
    }

    if (this.tempList && this.tempList.length === 0) {
      this.loadTemps();
    }

    await this.categoryDocumentation();
    await this.MyFavorites();
    this.form.date_time = new Date();
  },
  methods: {
    ...mapActions({
      loadTemps: "formTemplate/list",
      doctorTemplate: "formTemplate/doctorTemplate",
      doctorTemplates: "formTemplate/doctorTemplates",
      categoryDocumentationList: "categoryDocumentation/index",
      save: "orders/patientMedicine",
      showProductRemainder: "movings/showrem",
      update: "conclusions/update",
    }),
    categoryDocumentation() {
      this.categoryDocumentationList({ relation: true })
        .then((res) => {
          this.data = res.data.category_forms;
        })
        .catch((err) => {});
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    append(product) {
      const item = this.items.find((item) => item.product_id == product.id);
      this.loadingItems = true;
      const payload = {
        product_id: product.id,
        warehouse_id: this.form.warehouse_id,
      };
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.items.push({
              product_id: product.id,
              available: product.available ? product.available : 0,
              quantity: 0,
              product: {
                name: product.name,
              },
            });
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    invalid_append(product) {
      const item = this.invalid_items.find(
        (item) => item.product_id == product.id
      );
      this.loadingItems = true;
      const payload = {
        product_id: product.id,
        warehouse_id: this.form.warehouse_id,
      };
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.invalid_items.push({
              product_id: product.id,
              available: product.available ? product.available : 0,
              quantity: 0,
              product: {
                name: product.name,
              },
            });
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const payload = {
            warehouse_id: this.form.warehouse_id,
            comment: this.form.comment,
            invalid_warehouse_id: this.form.invalid_warehouse_id,
            items: this.items,
            invalid_items: this.invalid_items,
            patient_id: this.order_model.patient.id,
            order_id: this.order_model.id,
          };

          this.save(payload)
            .then((response) => {
              this.$alert(response);
              if (close) this.resetForm("form");
              this.$emit("button", payload);
            })
            .catch((error) => {
              this.$alert(error);
            });
        }
      });
    },
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    removeWriteOffInvalidItem(item) {
      const index = this.invalid_items.indexOf(item);
      this.invalid_items.splice(index, 1);
    },
    updateConculation() {
      this.loadingButton = true;
      this.update({ id: this.old_form.id, data: this.form })
        .then((res) => {
          this.old_form = res.data.patient_conculation;
          this.loadingButton = false;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.activeName = "three";
          this.active++;
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    tabTrigger(event, tab_value) {
      if (this.active >= event) {
        this.active = event - 1;
        this.activeName = tab_value;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {
        name: "",
        service_id: null,
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      };
      this.items = [];
      this.old_form = "";
      this.active = 0;
      this.activeName = "first";
      this.templates = [];
      this.selectedTemplate = {};
      this.nextButton = true;
      this.$emit("c-close", { drawer: "drawerCreateCoculation" });
    },
    NextTab() {
      this.activeName = "second";
      this.active++;
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
    stringToHTML(str) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(str, "text/html");
      return doc.body;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return "warning-row";
      }
    },
    selected(event) {
      if (event.category_id) {
        let tm = _.find(this.tempList, function (item) {
          return item.id == event.id;
        });
        if (tm) {
          this.selectedTemplate = tm;
          this.form.form_template_id = tm.id;
          this.form.content = this.form.content + "<br>" + tm.content;
        }
      } else {
        this.selectedTemplate = {};
        this.form.form_template_id = null;
        this.form.content = "";
      }
      if (this.form.content != "") this.nextButton = false;
      else this.nextButton = true;
      this.setToTinyContent();
    },
    addFavorites(id) {
      this.doctorTemplate({ template_id: id })
        .then((res) => {
          this.MyFavorites();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    MyFavorites() {
      this.doctorTemplates()
        .then((res) => {
          this.favorites = res.data;
          this.categoryDocumentation();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.form.file = event.target.files[0];
      }
    },
    afterOpened() {
      this.form.service_id = this.serviceId;
    },
    afterClosed() {
      this.form.content = "";
      this.setToTinyContent();
    },
    clear() {
      this.form.content = "";
      this.setToTinyContent();
    },
    chanseService(id) {
      this.form.service_id = id;
    },
    setToTinyContent() {
      window.tinymce.get(this.tinymceId).setContent(this.form.content || "");
    },
  },
};
</script>